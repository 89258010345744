<template>
  <div v-if="canAccess('rp_matricula')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <Toolbar class="p-col-12">
      <template #right>
        <br><br>
        <Button label="Exportar" icon="pi pi-file-excel" class="p-button-secondary p-mr-2"
                @click="exportReport('XLS')"/>
        <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2"
                @click="exportReport('PDF')"/>
      </template>
      <template #left style="display: grid">
        <div class="grid" style="display: grid">
          <div class="p-col-12">
            <h6>Informe mensual de matrículas</h6>
          </div>
          <div class="p-col-12" style="display: inline-flex">
            <div class="p-col-5 p-field p-fluid">
                            <span class="p-float-label">
                               <AutoComplete id="month" :dropdown="true" :multiple="false" field="month"
                                             v-model="data.month" :suggestions="months"
                                             :class="{'p-invalid': (!data.month )}"
                                             @complete="getCatalog($event, 'months', 'month')" class="p-inputtext-sm"/>
                                <label for="month">Mes</label>
                            </span>
            </div>
            <div class="p-col-6 p-field p-fluid">
              <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" :disabled="!data.month"
                      @click="getData()"/>
            </div>

          </div>
        </div>
      </template>
    </Toolbar>
    <DataTable :value="items" rowGroupMode="subheader" groupRowsBy="country">
      <ColumnGroup type="header">
        <Row>
          <Column header="Filial" :rowspan="2"/>
          <Column header="Ciudad" :rowspan="2"/>
          <Column header="Matrículas" :colspan="2"/>
        </Row>
        <Row>
          <Column header="Semana" :sortable="true" field="total_weekly_completed"/>
          <Column header="N. Matrículas" :sortable="true" field="total_weekly_pending"/>
        </Row>
      </ColumnGroup>

      <Column field="country" header="País">
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.country }}</span>
        </template>
      </Column>
      <Column :sortable="true" field="subsidiary"/>
      <Column :sortable="true" field="city"/>
      <Column :sortable="true" field="week"/>
      <Column :sortable="true" field="total"/>
      <template #groupheader="slotProps">
        <span class="image-text">{{ slotProps.data.country }}</span>
      </template>
      <template #groupfooter="slotProps">
        <td colspan="3" style="text-align: right">Total en {{ slotProps.data.country }}</td>
        <td>{{ slotProps.data.totalOfCountry }}</td>
      </template>

      <!--            <ColumnGroup type="footer">-->
      <!--                <Row>-->
      <!--                    <Column footer="Totals:" :colspan="2" footerStyle="text-align:right"/>-->
      <!--                    <Column :footer="total" />-->
      <!--                </Row>-->
      <!--            </ColumnGroup>-->

    </DataTable>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../service/report.service'
import {mapGetters} from "vuex";
import catalogueService from "../../core/service/catalog.service";
import bouncer from "../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: "EnrollMonthlyReport",
  data() {
    return {
      service: service('months'),
      items: [],
      total: [],
      loading: false,
      weeks: [],
      months: [],
      data: {}
    }
  },
  mounted() {
    if (this.canAccess('rp_matricula')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.items = [];
      this.service.getEnrollments(this.data.month?.id).then(x => {
        x.data.countries.forEach(p => {
          p.cities.forEach(c => {
            c.subsidiaries.forEach(s => {
              this.items = this.items.concat(s.weeks.map(w => {
                return {
                  ...w,
                  subsidiary: s.name,
                  city: c.name,
                  country: p.name,
                  totalOfCountry: p.total
                }
              }));
            });
          });
        });
      }).catch(err => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)
    },
    exportReport(command) {
      this.loading = true;
      this.service.getEnrollments(this.data.month?.id, {export: command}).then(x => {
        window.open(x.data.url_file)
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)


    },
    getCatalog(event, route, field) {
      const originalRoute = route;
      catalogueService.getCatalog(event, route, field).then(x => {
        this[originalRoute] = x;
      }).catch(() => {
        this[originalRoute] = [];
      });
    },
  },
  computed: {
    ...mapGetters('bread', ['breadcrumb']),
  }
}
</script>

<style scoped>

span {
  width: 100%;
}

.image-text {
  font-weight: 700;
}
</style>
